<template>
  <div class="container my-5">
    <div v-if="getData.length" class="speciesPage">
      <div class="row flex-column flex-md-row">
        <h4 class="col-12 mt-3 mb-5">
          {{ plantData.laName }}
          <b-icon
            v-if="plantData.redBook"
            icon="book"
            variant="danger"
          ></b-icon>
        </h4>
        <div class="row col-12 flex-column flex-md-row">
          <div class="col-12 col-md-5 mb-4">
            <div style="max-width: 450px;">
              <ZoomImage
                :image-url="
                  plantData.image
                    ? plantData.image
                    : require('@/assets/profile/empty-image.jpg')
                "
              />
            </div>
          </div>
          <div class="col-12 col-md-5 mb-4 ml-md-4">
            <div class="row">
              <div class="d-flex w-100">
                <div class="col-12 col-md-5 text-left mb-2 mb-md-0">
                  <h5>{{ $t('plants_form_page_name_title') }}</h5>
                </div>
                <div class="text-left col-12 col-md-7 mt-1">
                  <p>
                    <strong>
                      {{ $t('plant_item_page_name_title_enName') }}
                    </strong>
                    {{ plantData.enName }}
                  </p>
                  <p>
                    <strong>
                      {{ $t('plant_item_page_name_title_ruName') }}
                    </strong>
                    {{ plantData.ruName }}
                  </p>
                  <p>
                    <strong>
                      {{ $t('plant_item_page_name_title_laName') }}
                    </strong>
                    {{ plantData.laName }}
                  </p>
                </div>
              </div>
              <div class="d-flex w-100 mt-3">
                <div class="col-12 col-md-5 text-left mb-2 mb-md-0">
                  <h5>{{ $t('herbarium_form_page_input_genus_holotype') }}</h5>
                </div>
                <div class="text-left col-12 col-md-7 mt-1">
                  <p>
                    {{ plantData.holotypeId ? plantData.holotypeId : '-' }}
                  </p>
                </div>
              </div>
              <div class="d-flex w-100 mt-3">
                <div class="col-12 col-md-5 text-left mt-2 mb-md-0">
                  <h5>{{ $t('plant_item_page_name_title_classification') }}</h5>
                </div>
                <div class="text-left col-12 col-md-7 mt-1">
                  <p>
                    <strong>
                      {{ $t('plant_item_page_name_classification_family') }}
                    </strong>
                    {{
                      plantData.category && plantData.category.category
                        ? plantData.category.category.laName
                        : '-'
                    }}
                  </p>
                  <p>
                    <strong>
                      {{ $t('plant_item_page_name_classification_genus') }}
                    </strong>
                    {{ plantData.category ? plantData.category.laName : '-' }}
                  </p>
                  <p>
                    <strong>
                      {{ $t('plant_item_page_name_classification_species') }}
                    </strong>
                    {{
                      `${plantData.category.laName[0].toUpperCase()}.${
                        plantData.laName
                      }`
                    }}
                  </p>
                </div>
              </div>
              <div class="d-flex w-100 mt-3">
                <div class="col-12 col-md-5 text-left mt-2 mb-md-0">
                  <h5>{{ $t('plant_item_page_synonyms_title') }}</h5>
                </div>
                <div class="text-left col-12 col-md-7 mt-1">
                  <ul v-if="plantData.synonyms" class="pl-3">
                    <li
                      v-for="(synonym, i) in plantData.synonyms.split(',')"
                      :key="i"
                    >
                      {{ synonym }}
                    </li>
                  </ul>
                  <p v-else>-</p>
                </div>
              </div>
            </div>
          </div>
          <div class="text-left col-12 my-3 ml-3 plantDescription">
            <p>
              <strong>
                {{ $t('plant_item_page_name_description') }}
              </strong>
              {{ plantData.description }}
            </p>
          </div>
          <div class="ml-3">
            <VueJsonToCsv
              :json-data="plantDataCsv"
              :labels="{
                English: {
                  title:
                    $t('plants_form_page_name_title').toUpperCase() +
                    ' ' +
                    $t('plant_item_page_name_title_enName').toUpperCase()
                },
                Russian: {
                  title:
                    $t('plants_form_page_name_title').toUpperCase() +
                    ' ' +
                    $t('plant_item_page_name_title_ruName').toUpperCase()
                },
                Latin: {
                  title:
                    $t('plants_form_page_name_title').toUpperCase() +
                    ' ' +
                    $t('plant_item_page_name_title_laName').toUpperCase()
                },
                Holotype: {
                  title: $t(
                    'herbarium_form_page_input_genus_holotype'
                  ).toUpperCase()
                },
                Family: {
                  title:
                    $t(
                      'plant_item_page_name_title_classification'
                    ).toUpperCase() +
                    ' ' +
                    $t(
                      'plant_item_page_name_classification_family'
                    ).toUpperCase()
                },
                Genus: {
                  title:
                    $t(
                      'plant_item_page_name_title_classification'
                    ).toUpperCase() +
                    ' ' +
                    $t(
                      'plant_item_page_name_classification_genus'
                    ).toUpperCase()
                },
                Species: {
                  title:
                    $t(
                      'plant_item_page_name_title_classification'
                    ).toUpperCase() +
                    ' ' +
                    $t(
                      'plant_item_page_name_classification_species'
                    ).toUpperCase()
                },
                Synonyms: {
                  title: $t('plant_item_page_synonyms_title').toUpperCase()
                },
                Description: {
                  title: $t('plant_item_page_name_description').toUpperCase()
                },
                GPS: {
                  title: $t('herbarium_form_page_input_gps_label').toUpperCase()
                },
                Area: {
                  title: $t(
                    'herbarium_form_page_input_area_label'
                  ).toUpperCase()
                },
                Habitation: {
                  title: $t(
                    'herbarium_form_page_input_habitation_label'
                  ).toUpperCase()
                },
                Phenology: {
                  title: $t(
                    'herbarium_form_page_input_phenology_label'
                  ).toUpperCase()
                },
                Zone: {
                  title: $t(
                    'herbarium_form_page_input_zone_label'
                  ).toUpperCase()
                },
                Soil: {
                  title: $t(
                    'herbarium_form_page_input_ground_label'
                  ).toUpperCase()
                },
                Altitude: {
                  title: $t(
                    'herbarium_form_page_input_elevation_label'
                  ).toUpperCase()
                },
                Ridge: {
                  title: $t(
                    'herbarium_form_page_input_ridge_label'
                  ).toUpperCase()
                },
                ColourOfFlower: {
                  title: $t(
                    'herbarium_form_page_input_colour_of_flower_label'
                  ).toUpperCase()
                },
                NaturalBoundary: {
                  title: $t(
                    'herbarium_form_page_input_natural_boundary_label'
                  ).toUpperCase()
                },
                DateCollected: {
                  title: $t(
                    'herbarium_form_page_input_datepicker_label'
                  ).toUpperCase()
                },
                CatalogueNumber: {
                  title: $t(
                    'herbarium_form_page_input_catalogueNumber_label'
                  ).toUpperCase()
                },
                SpeicmenCollector: {
                  title: $t(
                    'herbarium_form_page_input_specimentCollector_label'
                  ).toUpperCase()
                },
                SpeicmenIdentifier: {
                  title: $t(
                    'herbarium_form_page_input_specimentIdentifier_label'
                  ).toUpperCase()
                },
                Photographer: {
                  title: $t(
                    'herbarium_form_page_input_photographer_label'
                  ).toUpperCase()
                },
                Tag: {
                  title: $t('herbarium_form_page_input_tags').toUpperCase()
                }
              }"
            >
              <b-button variant="success">
                {{ $t('plant_item_page_name_csv_download') }}
              </b-button>
            </VueJsonToCsv>
          </div>
        </div>
        <div class="col-12 my-3 plantMap">
          <Map :specices-id="+speciesID" read-only />
        </div>
        <div class="text-left col-12 mt-3 pl-4">
          <h5 class="pl-3">{{ $t('plant_item_page_all_herbarium') }}</h5>
          <div class="herbariumList">
            <div class="table-wrap">
              <b-table
                id="herbarium-table"
                hover
                :items="getData"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                @row-clicked="item => goTodetail(item.id)"
              >
                <template #head()="data">
                  <div>
                    <span>{{ $t(data.label) }}</span>
                  </div>
                </template>
                <template v-slot:cell(image)="row">
                  <img class="plantImage" :src="`${row.value}`" />
                </template>
                <template v-slot:cell(ruName)="row">
                  <span>{{ row.item.ruName }}</span>
                </template>
                <template v-slot:cell(enName)="row">
                  <span>{{ row.item.enName }}</span>
                </template>
                <template v-slot:cell(collectedDate)="row">
                  <span>{{ row.item.collectedDate }}</span>
                </template>
                <template v-slot:cell(phenology)="row">
                  <span>{{ row.item.phenology }}</span>
                </template>
                <template v-slot:table-caption>
                  <b-pagination
                    v-model="currentPage"
                    :per-page="perPage"
                    :total-rows="getCount"
                    size="sm"
                    first-number
                    last-number
                    aria-controls="herbarium-table"
                    class="mt-4"
                  />
                </template>
              </b-table>
            </div>
            <div v-if="isLoading" class="pb-5">
              <div class="m-auto">
                <b-spinner variant="success" label="Text Centered"></b-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VueJsonToCsv from 'vue-json-to-csv'
import ZoomImage from '@/components/ZoomImage'
import Map from '@/components/Map.vue'

export default {
  name: 'SpeciesPage',
  components: {
    ZoomImage,
    Map,
    VueJsonToCsv
  },
  data() {
    return {
      speciesID: this.$route.params.id,
      currentPage: 1,
      perPage: 5,
      fields: [
        {
          key: 'image',
          thClass: 'thead_',
          label: 'plant_item_page_list_photo'
        },
        {
          key: 'ruName',
          thClass: 'thead_',
          label: 'plant_item_page_list_ruName'
        },
        {
          key: 'enName',
          thClass: 'thead_',
          label: 'plant_item_page_list_enName'
        },
        {
          key: 'collectedDate',
          thClass: 'thead_',
          label: 'plant_item_page_list_collectedDate'
        },
        {
          key: 'phenology',
          thClass: 'thead_',
          label: 'plant_item_page_list_phenology'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('herbarium', ['getData', 'getCount', 'isLoading']),
    plantData() {
      return this.getData[0].plant
    },
    plantDataCsv() {
      let arr = []
      arr.push({
        English: this.plantData.enName ? this.plantData.enName : '-',
        Russian: this.plantData.ruName ? this.plantData.ruName : '-',
        Latin: this.plantData.laName ? this.plantData.laName : '-',
        Holotype: this.plantData.holotypeId ? this.plantData.holotypeId : '-',
        Family:
          this.plantData.category && this.plantData.category.category
            ? this.plantData.category.category.laName
            : '-',
        Genus: this.plantData.category ? this.plantData.category.laName : '-',
        Species: `${this.plantData.category.laName[0].toUpperCase()}.${
          this.plantData.laName
        }`,
        Synonyms: this.plantData.synonyms ? this.plantData.synonyms : '-',
        Description: this.plantData.description
          ? this.plantData.description
          : '-',
        GPS: `${this.getData[0].latitude},${this.getData[0].longitude}`,
        Area: this.getData[0].area ? this.getData[0].area : '-',
        Habitation: this.getData[0].habitation
          ? this.getData[0].habitation
          : '-',
        Phenology: this.getData[0].phenology ? this.getData[0].phenology : '-',
        Zone: this.getData[0].zone ? this.getData[0].zone : '-',
        Soil: this.getData[0].ground ? this.getData[0].ground : '-',
        Altitude: this.getData[0].elevation ? this.getData[0].elevation : '-',
        Ridge: this.getData[0].ridge ? this.getData[0].ridge : '-',
        ColourOfFlower: this.getData[0].colourOfFlower
          ? this.getData[0].colourOfFlower
          : '-',
        NaturalBoundary: this.getData[0].naturalBoundary
          ? this.getData[0].naturalBoundary
          : '-',
        DateCollected: this.getData[0].collectedDate
          ? this.getData[0].collectedDate
          : '-',
        CatalogueNumber: this.getData[0].catalogueNumber
          ? this.getData[0].catalogueNumber
          : '-',
        SpeicmenCollector: this.getData[0].specimentCollector
          ? this.getData[0].specimentCollector
          : '-',
        SpeicmenIdentifier: this.getData[0].specimentIdentifier
          ? this.getData[0].specimentIdentifier
          : '-',
        Photographer: this.getData[0].photographer
          ? this.getData[0].photographer
          : '-',
        Tag: this.getData[0].tags[0].name ? this.getData[0].tags[0].name : '-'
      })
      return arr
    }
  },
  async mounted() {
    await this.fetchDataFilter(`/herbarium?species=${this.speciesID}`)
  },
  methods: {
    ...mapActions('herbarium', ['fetchDataFilter']),
    goTodetail(itemId) {
      this.$router
        .push({ name: 'herbariumItem', params: { id: itemId } })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss">
.container {
  padding-left: 0;
  padding-right: 0;
  .speciesPage {
    padding: 0 2rem 2rem 2rem;
    background: #ffffff;
    box-shadow: 0px 4px 64px rgba(216, 231, 255, 0.15);
    border-radius: 4px;
    .information {
      display: none;
    }
    .map-wrap {
      max-height: 400px !important;
    }
  }
  .plantDescription {
    padding: 1rem;
    border: 1px solid #4c7464;
    box-shadow: 0px 4px 64px rgba(216, 231, 255, 0.15);
    border-radius: 4px;
  }
  .plantImage {
    height: 80px;
    width: auto;
  }
}

.herbariumList {
  .thead_ {
    background: #f6f6f6;
    color: #373a3c;
    font-size: 0.9rem;
    border: none;
    vertical-align: middle;
    border-radius: 4px 4px 0px 0px;
    padding: 0.4rem 0 0.4rem 2rem;
    text-align: left;
    @media (max-width: 768px) {
      line-height: 1.5;
      font-size: 0.8rem;
    }
    &:last-child {
      min-width: 50px;
    }
  }
  /deep/.table-wrap {
    position: relative;
    min-height: 380px;
    .b-table {
      caption {
        position: absolute;
        bottom: 0;
      }
      tbody {
        tr {
          cursor: pointer;
          &:hover {
            background-color: #f4f8ff;
          }
          td {
            border-top: none;
            border-bottom: 1px solid #f1f1f1;
            vertical-align: middle;
            text-align: left;
            padding: 0.9rem 0 0.9rem 2rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;
          }
        }
      }
    }
  }
}
</style>
